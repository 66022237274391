
import { defineComponent } from "vue";
import { useRouter } from "vue-router";
import FileCard from "@/components/ListCard/FileCard.vue";
import SearchBar from "@/components/SearchBar.vue";
import { TaskService } from "@/services/task/TaskService";
import Utils from "@/utils/Utils";
export default defineComponent({
  name: "File",
  components: { SearchBar, FileCard },
  data() {
    return {
      taskService: new TaskService(),
      list: new Array(0),
      router: useRouter(),
      cancelSwitch: false, //接口开关
      isedit: false,
      operabilityCard: false,
      isMore: true,
      taskParams: {
        limit: 10,
        offset: 1,
        name: "",
      },
    };
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      this.requsetData(this.taskParams, null);
      this.list = [
        {
          name: "项目需求规格说明书",
        },
        {
          name: "项目需求规格说明书",
        },
        {
          name: "项目需求规格说明书",
        },
      ];
    },
    //下拉刷新
    doRefresh(event: any) {
      this.isMore = true; 
      this.taskParams.offset = 1;
      this.isedit = false;
      this.initData();
      setTimeout(() => {
        event.target.complete();
      }, 500);
    },
    //下一页数据
    nextPage(event: any) {
      if (this.isMore) {
        this.taskParams.offset++;
        this.requsetData(this.taskParams, event);
      }
    },
    //搜索
    searchHandler(value: any) {
      if (Utils.isInCludeEmoji(value.searchKey)) {
        Utils.presentToastWarning("搜索内容不可以包含表情");
        return;
      }
      this.taskParams.offset = 1;
      this.taskParams.name = value.searchKey;
      this.requsetData(this.taskParams, null);
    },
    //加载数据
    requsetData(pamas: any, event: any) {
      //    console.log(event);
    },
    //是否是编辑
    isEdit(isedit: any) {
      this.isedit = isedit;
      let list_box = document.querySelector(".list_box") as HTMLCanvasElement;
      if (this.isedit) {
        list_box.setAttribute("class", "list_box show");
        return;
      } else {
        list_box.setAttribute("class", "list_box hidden");
      }
    },
    //跳转详情
    itemClick(item: any) {
      if (!this.isedit) {
        this.router.push({
          path: "/task-page",
          query: {
            id: item.id,
          },
        });
      }
    },
    //移除
    cancel(id: string) {
      if (this.isedit) {
        console.log(id);
      }
    },
  },
});
