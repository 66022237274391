
import { defineComponent } from "vue";
import { useRouter } from "vue-router";
import ProjectCss from "@/components/ListCard/ProjectCss.vue";
import { getInstance } from "@/services/project/projectService";
import Utils from "@/utils/Utils";
import SearchBar from "@/components/SearchBar.vue";
export default defineComponent({
  name: "Project",
  components: { SearchBar, ProjectCss },
  data() {
    const projectService = getInstance();
    return {
      projectService: projectService,
      list: new Array(0),
      router: useRouter(),
      isMore: true,
      cancelSwitch: false, //接口开关
      isedit: false,
      projectParams: {
        limit: 10,
        offset: 1,
        projectName: "",
        isDeleted: 1,
        sort: "-refresh_time",
      },
    };
  },
  created() {
    this.initData();
  },
  watch: {
    $route() {
      this.list = [];
      this.initData();
    },
  },
  methods: {
    initData() {
      this.projectParams.offset = 1;
      this.requsetData(this.projectParams, null);
    },
    //下拉刷新
    doRefresh(event: any) {
      this.isMore = true;
      this.projectParams.offset = 1;
      this.isedit = false;
      this.initData();
      setTimeout(() => {
        event.target.complete();
      }, 500);
    },
    //下一页数据
    nextPage(event: any) {
      if (this.isMore) {
        this.projectParams.offset++;
        this.requsetData(this.projectParams, event);
      }
    },
    //加载数据
    requsetData(pamas: any, event: any) {
      this.projectService.getProjectList(pamas).then(
        (r: any) => {
          if (r) {
            const result = r.data;
            if (result == null || result.length <= 0) {
              if (event) {
                event.target.disabled = true;
              } else {
                this.list = [];
              }
            } else {
              if (event != null) {
                this.list = this.list.concat(result);
              } else {
                this.list = result;
              }
              setTimeout(() => {
                if (event) {
                  event.target.complete();
                }
              }, 500);
            }
          } else {
            if (event != null) {
              this.list = [];
            }
          }
        },
        (e: any) => {}
      );
    },
    //搜索
    searchHandler(value: any) {
      if (Utils.isInCludeEmoji(value.searchKey)) {
        Utils.presentToastWarning("搜索内容不可以包含表情");
        return;
      }
      this.projectParams.offset = 1;
      this.projectParams.projectName = value.searchKey;
      this.requsetData(this.projectParams, null);
    },
    //是否是编辑
    isEdit(isedit: any) {
      this.isedit = isedit;
      let list_box = document.querySelector(".list_box") as HTMLCanvasElement;
      if (this.isedit) {
        list_box.setAttribute("class", "list_box show");
        return;
      } else {
        list_box.setAttribute("class", "list_box hidden");
      }
    },
    //跳转详情
    itemClick(item: any) {
      if (!this.isedit) {
        this.router.push({
          path: "/ProjectPage",
          query: {
            projectId: item.id,
            projectName: item.name,
            isStart: item.isStart,
          },
        });
      }
    },
    //移除
    cancel(id: string) {
      if (this.isedit && !this.cancelSwitch) {
        this.cancelSwitch = true;
        this.projectService.recycleProject(id).then(
          (r: any) => {
            if (r) {
              this.initData();
              Utils.presentToast("项目还原成功");
              this.cancelSwitch = false;
            }
          },
          (e: any) => {
            Utils.presentToastWarning(e.massage);
          }
        );
      }
    },
  },
});
