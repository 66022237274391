
import { defineComponent } from "vue";
import Header from "@/components/Header.vue";
import Project from "./Project.vue";
import Task from "./Task.vue";
import File from "./File.vue";
import Schedule from "./Schedule.vue";
import Calendar from "./Calendar.vue";
import TargetList from "@/views/OKR/OKRManage/TargetList.vue"
import MicroIssues from "./MicroIssues.vue";
import SearchBar from "@/components/SearchBar.vue";
import Setting from "@/rest/Setting";
import Utils from "@/utils/Utils";
export default defineComponent({
  name: "Recycle",
  components: {
    Header,
    Project,
    Task,
    Schedule,
    Calendar,
    TargetList,
    MicroIssues,
    File,
    SearchBar,
  },
  data() {
    let indicators = ["项目", "任务", "日程" /* , "微议题" */, "日历", "OKR目标"];
    return {
      btnTextRight: ["编辑"],
      btnSizeRight: ["34px"],
      indicators: indicators,
      selSeg: 0,
      isEdit: false,
      $refs: {
        projectDom: Project,
        taskDom: Task,
        fileDom: File,
        scheduleDom: Schedule,
        microIssuesDom: MicroIssues,
        calendarDom: Calendar,
        targetList: TargetList
      },
      searchValue: "",
      //搜索框的提示内容
      selSegStr: "按照名称搜索",
      targetList:new Array(),
    };
  },
  created() {},
  methods: {
    //滚动到顶部
    scrollToTop() {
      let ionContent: any = document.querySelector("#recycleContent");
      if (ionContent) {
        ionContent.scrollToTop();
      }
    },
    segmentChanged(ev: any) {
      this.selSeg = ev.detail.value;
      this.isEdit = false;
      this.btnTextRight = ["编辑"];
      this.searchValue = "";
      this.scrollToTop();
    },
    clearSearchValue(ev: any) {
      this.searchValue = ev.searchKey;
    },
    //编辑 调用子组件方法
    onRightClick() {
      this.isEdit = !this.isEdit;
      if (this.isEdit) {
        this.btnTextRight = ["完成"];
      } else {
        this.btnTextRight = ["编辑"];
      }
      this.reflushData();
    },
    //搜索 给子组件传值
    searchHandler(value: any) {
      if (Utils.isInCludeEmoji(value.searchKey)) {
        Utils.presentToastWarning("搜索内容不可以包含表情");
        return;
      }
      // this.searchValue = value.searchKey;
      this.reflushSearch(value);
    },
    reflushSearch(searchName: string) {
      let selSeg = this.selSeg + "";
      switch (selSeg) {
        case "0":
          this.$refs.projectDom.searchHandler(searchName);
          break;
        case "1":
          this.$refs.taskDom.searchHandler(searchName);
          break;
        case "2":
          this.$refs.scheduleDom.searchHandler(searchName);
          break;
        case "3":
          // this.$refs.microIssuesDom.searchHandler(searchName);
          this.$refs.calendarDom.searchHandler(searchName);
          break;
        case "4":
          this.$refs.targetList.searchHandler(searchName);
          break;
      }
    },
    reflushData() {
      let selSeg = this.selSeg + "";
      switch (selSeg) {
        case "0":
          this.$refs.projectDom.isEdit(this.isEdit);
          break;
        case "1":
          this.$refs.taskDom.isEdit(this.isEdit);
          break;
        case "2":
          this.$refs.scheduleDom.isEdit(this.isEdit);
          break;
        case "3":
          // this.$refs.microIssuesDom.isEdit(this.isEdit);
          this.$refs.calendarDom.isEdit(this.isEdit);
          break;
        case "4":
          this.$refs.targetList.isEdit(this.isEdit);
          break;
      }
    },
  },
});
