
import { defineComponent } from "vue";
import { useRouter } from "vue-router";
import TaskCards from "@/components/ListCard/TaskCards.vue";
import SearchBar from "@/components/SearchBar.vue";
import { getInstance } from "@/services/MicroIssuesService";
import Utils from "@/utils/Utils";
export default defineComponent({
  name: "MicroIssues",
  components: { SearchBar, TaskCards },
  data() {
    const service = getInstance();
    return {
      service: service,
      list: new Array(0),
      router: useRouter(),
      cancelSwitch: false, //接口开关
      isedit: false,
      operabilityCard: false,
      isMore: true,
      microIssuesParams: { offset: 1, name: "" },
    };
  },
  created() {
    this.initData();
  },
  watch: {
    $route() {
      this.list = [];
      this.initData();
    },
  },
  methods: {
    initData() {
      this.microIssuesParams.offset = 1;
      this.requsetData(this.microIssuesParams, null);
    },
    //下拉刷新
    doRefresh(event: any) {
      this.isMore = true;
      this.microIssuesParams.offset = 1;
      this.isedit = false;
      this.initData();
      setTimeout(() => {
        event.target.complete();
      }, 500);
    },
    //下一页数据
    nextPage(event: any) {
      if (this.isMore) {
        this.microIssuesParams.offset++;
        this.requsetData(this.microIssuesParams, event);
      }
    },
    //搜索
    searchHandler(value: any) {
      if (Utils.isInCludeEmoji(value.searchKey)) {
        Utils.presentToastWarning("搜索内容不可以包含表情");
        return;
      }
      this.microIssuesParams.offset = 1;
      this.microIssuesParams.name = value.searchKey;
      this.requsetData(this.microIssuesParams, null);
    },
    //加载数据
    requsetData(pamas: any, event: any) {
      this.service.getMicroIssuesRecycleList(pamas).then(
        (r: any) => {
          if (r) {
            const result = r;
            if (result == null || result.length <= 0) {
              if (event) {
                event.target.disabled = true;
              } else {
                this.list = [];
              }
            } else {
              if (event != null) {
                this.list = this.list.concat(result);
              } else {
                this.list = result;
              }
              setTimeout(() => {
                if (event) {
                  event.target.complete();
                }
              }, 500);
            }
          } else {
            if (event != null) {
              this.list = [];
            }
          }
        },
        (e: any) => {}
      );
    },
    //是否是编辑
    isEdit(isedit: any) {
      this.isedit = isedit;
      let list_box = document.querySelector(".list_box") as HTMLCanvasElement;
      if (this.isedit) {
        list_box.setAttribute("class", "list_box show");
        return;
      } else {
        list_box.setAttribute("class", "list_box hidden");
      }
    },
    //跳转详情
    itemClick(issues: any) {
      if (!this.isedit) {
        this.router.push({
          name: "MicroIssuesInfo",
          params: { issues: JSON.stringify(issues) },
        });
      }
    },
    //还原
    cancel(id: string) {
      if (this.isedit && !this.cancelSwitch) {
        this.cancelSwitch = true;
        this.service.recycleIssues(id + "", true).then(
          (r: any) => {
            if (r) {
              this.initData();
              Utils.presentToast("微议题还原成功");
              this.cancelSwitch = false;
            }
          },
          (e: any) => {
            Utils.presentToastWarning(e.massage);
          }
        );
      }
    },
  },
});
