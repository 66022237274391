
import { defineComponent } from "vue";
import ScheduleCard from "@/components/ListCard/ScheduleCard.vue";
import { useRouter } from "vue-router";
import Utils from "@/utils/Utils";
import SearchBar from "@/components/SearchBar.vue";
//Service
import { ScheduleService } from "@/services/schedule/ScheduleService.ts";
import { CalendarNative, EventOption } from "@/utils/CalendarNative";
import { isPlatform } from "@ionic/vue";

export default defineComponent({
  name: "Schedule",
  components: { SearchBar, ScheduleCard },
  data() {
    const calendarNative = new CalendarNative();
    return {
      scheduleService: new ScheduleService(),
      calendarNative: calendarNative,
      operabilityCard: false, //是否可操作
      list: new Array(0),
      scheduleParams: {
        limit: 10,
        offset: 1,
        name: "",
      },
      isedit: false,
      router: useRouter(),
      isMore: true,
      cancelSwitch: false, //接口开关
    };
  },
  created() {
    this.initData();
  },
  watch: {
    $route() {
      this.list = [];
      this.initData();
    },
  },
  methods: {
    initData() {
      this.scheduleParams.offset = 1;
      this.requsetData(this.scheduleParams, null);
      this.initPermission();
    },
    initPermission() {
      this.calendarNative.checkReadWritePermission().then(
        (bo: boolean) => {},
        (e: boolean) => {}
      );
    },
    //下拉刷新
    doRefresh(event: any) {
      this.isMore = true;
      this.scheduleParams.offset = 1;
      this.isedit = false;
      this.initData();
      setTimeout(() => {
        event.target.complete();
      }, 500);
    },
    //下一页数据
    nextPage(event: any) {
      if (this.isMore) {
        this.scheduleParams.offset++;
        this.requsetData(this.scheduleParams, event);
      }
    },
    //搜索
    searchHandler(value: any) {
      if (Utils.isInCludeEmoji(value.searchKey)) {
        Utils.presentToastWarning("搜索内容不可以包含表情");
        return;
      }
      this.scheduleParams.offset = 1;
      this.scheduleParams.name = value.searchKey;
      this.requsetData(this.scheduleParams, null);
    },
    //加载数据
    requsetData(pamas: any, event: any) {
      this.scheduleService.getRecycleSchedule(pamas).then(
        (r: any) => {
          if (r) {
            const result = r;
            if (result == null || result.length <= 0) {
              if (event) {
                event.target.disabled = true;
              } else {
                this.list = [];
              }
            } else {
              if (event != null) {
                this.list = this.list.concat(result);
              } else {
                this.list = result;
              }
              setTimeout(() => {
                if (event) {
                  event.target.complete();
                }
              }, 500);
            }
          } else {
            if (event != null) {
              this.list = [];
            }
          }
        },
        (e: any) => {}
      );
    },
    //是否是编辑
    isEdit(isedit: any) {
      this.isedit = isedit;
      let list_box = document.querySelector(".list_box") as HTMLCanvasElement;
      if (this.isedit) {
        list_box.setAttribute("class", "list_box show");
        return;
      } else {
        list_box.setAttribute("class", "list_box hidden");
      }
    },
    //跳转详情
    itemClick(item: any) {
      let params = { scheduleTimeId: item.id, id: item.scheduleId };
      if (!this.isedit) {
        // this.router.push({
        //   name: "detail-schedule-feedback",
        //   params: {
        //     info: JSON.stringify(params),
        //   },
        // });
        this.router.push({
          path: "/schedule/detail/feedback",
          query: {
            id: params.id,
            scheduleTimeId: params.scheduleTimeId,
            showCalendar: "false",
          },
        });
      }
    },
    //移除
    cancel(item: any) {
      if (this.isedit && !this.cancelSwitch) {
        this.cancelSwitch = true;
        this.scheduleService.restoreSchedule({ scheduleTimeId: item.id }).then(
          (r: any) => {
            this.initData();
            Utils.presentToast("日程还原成功");
            this.cancelSwitch = false;
            this.getScheduleInfo(item);
          },
          (e: any) => {
            Utils.presentToastWarning(e.massage);
          }
        );
      }
    },
    generateNotes(partUsers: Array<any>): string {
      let noteUsers = "";
      if (partUsers != undefined && partUsers.length > 0) {
        noteUsers = "参与人：";
        partUsers.forEach((user: any) => {
          noteUsers += user.userName + "、";
        });
        noteUsers = noteUsers.substring(0, noteUsers.length - 1);
      }
      return noteUsers;
    },
    compareMakeDate(startDate: Date, endDate: Date): Date {
      if (startDate.getTime() == endDate.getTime()) {
        endDate.setTime(endDate.getTime() + 1000 * 60);
      }
      return endDate;
    },
    getScheduleInfo(item: any) {
      const isRepeat: boolean = item.isRepeat;
      if (isRepeat) {
        return;
      }
      let params = {
        scheduleId: item.scheduleId,
        scheduleTimeId: item.id,
      };
      this.scheduleService.getScheduleDetail(params).then((result: any) => {
        let res = result.data;
        this.saveCalendarEvent(res);
      });
    },
    saveCalendarEvent(item: any) {
      const title: string = item.title;
      const location: string = item.location;
      const notes: string = item.notes;
      const startTime: string = item.startTime;
      const endTime: string = item.endTime;
      let startDate = new Date(startTime.replace(/\-/g, "/"));
      let endDate = new Date(endTime.replace(/\-/g, "/"));
      const partUsers: Array<any> = item.scheduleParticipantList;
      this.calendarNative.findAllCalendar().then(
        (r: any) => {
          if (r && r.length > 0) {
            let noteUsers = this.generateNotes(partUsers);
            let option: EventOption = {
              calendarName: "",
              calendarId: "",
              title: title,
              eventLocation: location,
              notes: noteUsers + (notes != "" ? "\n说明：" + notes : ""),
              startDate: startDate,
              endDate: this.compareMakeDate(startDate, endDate),
            };
            if (isPlatform("ios")) {
              option.calendarName = r[0].name;
            } else {
              option.calendarId = r[0].id;
            }
            //创建
            this.calendarNative.createEventSilently(option).then(
              (r: any) => {
                this.updateMobileScheduleId(item.scheduleId, r);
                console.log("日程标识：" + JSON.stringify(r));
              },
              (e: boolean) => {
                console.log("异常：" + JSON.stringify(e));
              }
            );
          } else {
            console.log("没有日历");
          }
        },
        (e: any) => {
          console.log("获取日历失败");
        }
      );
    },
    //更新手机事件标识
    updateMobileScheduleId(scheduleId: string, eventId: string) {
      let eventJson = { ios: "", android: "" };
      if (isPlatform("ios")) {
        eventJson.ios = eventId;
      } else {
        eventJson.android = eventId;
      }
      const params = {
        id: scheduleId,
        setFields: ["mobileScheduleId"],
        mobileScheduleId: JSON.stringify(eventJson),
      };
      this.scheduleService.updateFieldsById(params).then((res: any) => {});
    },
  },
});
